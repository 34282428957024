import { createSlice, Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IAiFlowState, AiFlow, AiFlowItem } from 'src/@types/aiflow';
// @types
import {
  AiFlowApi,
  AiFlowItemLogic,
  AiFlowItemNoteLogic,
  AiFlowLogic,
  AiFlowScheduleInfoLogic,
  AiFlowVersionApi,
  AiFlowVersionLogic,
  CopyMoveAiFolderRequest,
  CreateAiFlowVersionRequest,
  CustomActionLogic,
  TestActionRequest,
} from 'src/api';
import { PATH_MARKETING } from 'src/routes/paths';
import mapAiFlowItemData, { findLatestVersion, orderFlowItems } from 'src/utils/aiFlowsUtils';
import { addAiFlowRun, addAiFlowRunItems, fetchAiFlowRunsItems } from './aiFlow-runs';

// ----------------------------------------------------------------------

const initialState: IAiFlowState = {
  aiFlows: [],
  actionGroups: [],
  actions: [],
  customActions: [],
  copiedItems: [],
  addBeforeSequence: 0,
  sidebarPosition: 'left',
  tempAiFlowItem: null,
  sidebarPopoverOpen: null,
  selectedCustomAction: null,
  testActionResult: null,
  selectedAiFlow: null,
  selectedAiFlowVersion: null,
  selectedAiFlowItem: null,
  lastSavedSelectedAiFlow: null,
  error: null,
  actionValidated: true,
  fullscreen: false,
  testingAction: false,
  runsMode: false,
  loadingAiFlowVersions: false,
  savingAiFlow: false,
  loading: false,
  loaded: false,
  isDirty: false,
};

const slice = createSlice({
  name: 'aiFlows',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchAiFlowsSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      const svAiFlows = action.payload.aiFlows as AiFlowLogic[];
      if (!svAiFlows) {
        return;
      }
      const newAiFlows = svAiFlows as AiFlow[];
      newAiFlows.forEach((aiFlow) => {
        if (!aiFlow.items) {
          return;
        }
        aiFlow.items = aiFlow.items.map((item, index) => {
          const mappedItem = mapAiFlowItemData(
            item,
            aiFlow.items as AiFlowItem[],
            state.actions,
            state.actionGroups
          );

          return { ...mappedItem };
        });
        orderFlowItems(aiFlow.items, [], aiFlow.aiFlowVersions?.[0]?.id as any);
        // aiFlow.deletedItems = aiFlow.deletedItems?.map((item, index) => {
        //   const mappedItem = mapAiFlowItemData(
        //     item,
        //     aiFlow.items as AiFlowItem[],
        //     state.actions,
        //     state.actionGroups
        //   );
        //   return { ...mappedItem, sequence: index + 1 };
        // });
      });

      state.aiFlows = newAiFlows;
    },
    setCopiedItems(state, action) {
      state.copiedItems = action.payload;
    },
    fetchActionGroupsSuccess(state, action) {
      state.actionGroups = action.payload.actionGroups;
      state.actions = action.payload.actionGroups.flatMap((ag: any) => ag.actions);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoadingAiFlowVersions(state, action) {
      state.loadingAiFlowVersions = action.payload;
    },
    setFullscreen(state, action) {
      state.fullscreen = action.payload;
    },
    setActionValidated(state, action) {
      state.actionValidated = action.payload;
    },
    setRunMode(state, action) {
      state.runsMode = action.payload;
    },
    selectAiFlowItemBySequence(state, action) {
      const existAiFlowItem = state.selectedAiFlow?.items?.find(
        (item) =>
          item.sequence === action.payload &&
          (item.aiFlowVersionId ? item.aiFlowVersionId === state.selectedAiFlowVersion?.id : true)
      );
      // if (!existAiFlowItem) {
      //   existAiFlowItem = state.selectedAiFlow?.deletedItems?.find(
      //     (item) => item.sequence === action.payload
      //   );
      // }
      state.selectedAiFlowItem = existAiFlowItem ?? null;
    },
    setSidebarPopoverOpen(state, action) {
      state.sidebarPopoverOpen = action.payload.element;
      state.sidebarPosition = action.payload.position;
    },
    selectAiFlowItemById(state, action) {
      let existAiFlowItem = state.selectedAiFlow?.items?.find(
        (item) => item.id === action.payload.id
      );
      if (!existAiFlowItem) {
        existAiFlowItem = state.selectedAiFlow?.deletedItems?.find(
          (item) => item.id === action.payload.id
        );
      }
      if (existAiFlowItem) {
        existAiFlowItem.sequence = action.payload.sequence;
      }
      state.selectedAiFlowItem = existAiFlowItem ?? null;
    },
    setNodeClickedSequenceInfo(state, action) {
      state.addBeforeSequence = action.payload.addBeforeSequence;
      state.addToParentSequence = action.payload.addToParentSequence;
    },
    addAiFlowItemBeforeSequence(state, action) {
      if (!state.selectedAiFlow?.items) {
        return;
      }
      debugger;
      console.log('state.addBeforeSequence', state.addBeforeSequence);
      console.log('state.selectedAiFlow.items', _.cloneDeep(state.selectedAiFlow.items));

      const newAiFlowItemsBefore = state.selectedAiFlow.items.filter(
        (item) => (item?.sequence ?? 0) < state.addBeforeSequence
      );
      const newAiFlowItemsAfter = state.selectedAiFlow.items.filter(
        (item) => (item?.sequence ?? 0) >= state.addBeforeSequence
      );
      debugger;
      // newAiFlowItemsAfter.forEach((item) => {
      //   item.parentSequence = state.addToParentSequence ? state.addToParentSequence + 1 : undefined;
      // });
      const aiFlowItem = action.payload;
      const mappedItem = mapAiFlowItemData(
        aiFlowItem,
        state.selectedAiFlow.items,
        state.actions,
        state.actionGroups
      );
      mappedItem.aiFlowVersionId = state.selectedAiFlowVersion?.id as number;
      newAiFlowItemsBefore.push(mappedItem);
      const newAiFlowItems = [...newAiFlowItemsBefore, ...newAiFlowItemsAfter];
      orderFlowItems(
        newAiFlowItems,
        state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
        state.selectedAiFlowVersion?.id as any
      );
      console.log('newAiFlowItems', newAiFlowItems);
      state.selectedAiFlow.items = newAiFlowItems;
      state.aiFlows = state.aiFlows.map((af) => {
        if (af.id === state.selectedAiFlow?.id) {
          af.items = newAiFlowItems ?? [];
        }
        return af;
      });
      state.isDirty = true;
      state.loaded = true;
      state.savingAiFlow = false;
    },
    addAiFlowItemsBeforeSequence(state, action) {
      if (!state.selectedAiFlow?.items) {
        return;
      }
      const newAiFlowItemsBefore = state.selectedAiFlow.items.filter(
        (item) => (item?.sequence ?? 0) < state.addBeforeSequence
      );
      const newAiFlowItemsAfter = state.selectedAiFlow.items.filter(
        (item) => (item?.sequence ?? 0) >= state.addBeforeSequence
      );
      const aiFlowItems = action.payload;
      let newAiFlowItems = _.cloneDeep(newAiFlowItemsBefore);
      console.log('newAiFlowItems-1', _.cloneDeep(aiFlowItems));
      aiFlowItems.forEach((aiFlowItem: any, index: number) => {
        const mappedItem = mapAiFlowItemData(
          aiFlowItem,
          newAiFlowItems,
          state.actions,
          state.actionGroups
        );
        mappedItem.aiFlowVersionId = state.selectedAiFlowVersion?.id as number;
        newAiFlowItems.push(mappedItem);
      });
      console.log('newAiFlowItems0', _.cloneDeep(newAiFlowItems));
      newAiFlowItems = [...newAiFlowItems, ...newAiFlowItemsAfter];
      console.log('newAiFlowItems1', _.cloneDeep(newAiFlowItems));
      orderFlowItems(
        newAiFlowItems,
        state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
        state.selectedAiFlowVersion?.id as any
      );
      state.selectedAiFlow.items = newAiFlowItems;
      console.log('newAiFlowItem2', newAiFlowItems);
      state.aiFlows = state.aiFlows.map((af) => {
        if (af.id === state.selectedAiFlow?.id) {
          af.items = newAiFlowItems ?? [];
        }
        return af;
      });
      state.isDirty = true;
      state.loaded = true;
      state.savingAiFlow = false;
    },
    selectAiFlowItem(state, action) {
      const item = action.payload;
      //    item = mapAiFlowItemData(item, state.actions, state.actionGroups);
      state.selectedAiFlowItem = item;
    },
    addAiFlow(state, action) {
      const tmpAiFlow = action.payload as AiFlow;
      let aiFlowItems = tmpAiFlow.items ? (tmpAiFlow.items as AiFlowItem[]) : [];
      aiFlowItems = aiFlowItems.map((item, index) => {
        const mappedItem = mapAiFlowItemData(item, aiFlowItems, state.actions, state.actionGroups);
        mappedItem.sequence = index + 1;
        if (item.header !== 'Branch') {
          item.parentSequence = index > 0 ? index : undefined;
        } else {
          const lastSavedItem = state.lastSavedSelectedAiFlow?.items?.find((i) => i.id === item.id);
          if (lastSavedItem?.sequence === item.sequence) {
            item.parentSequence = lastSavedItem?.parentSequence;
          } else if ((lastSavedItem?.sequence as number) > (item.sequence as number)) {
            item.parentSequence =
              (lastSavedItem?.parentSequence as number) -
              ((lastSavedItem?.sequence as number) - (item.sequence as number));
          } else if ((lastSavedItem?.sequence as number) < (item.sequence as number)) {
            item.parentSequence =
              (lastSavedItem?.parentSequence as number) +
              ((item.sequence as number) - (lastSavedItem?.sequence as number));
          }
        }
        return mappedItem;
      });
      tmpAiFlow.items = aiFlowItems;
      state.aiFlows.push(tmpAiFlow);
    },
    setSelectedCustomAction(state, action) {
      state.selectedCustomAction = action.payload;
    },
    createNewVersionSuccess(state, action) {
      const newAiFlowVersion = action.payload.newVersion as AiFlowVersionLogic;
      const aiFlowId = newAiFlowVersion.aiFlowId as number;
      const aiFlow = state.aiFlows.find((af) => af.id === aiFlowId);
      const aiFlowIndex = state.aiFlows.findIndex((af) => af.id === aiFlowId);
      if (!aiFlow) {
        return;
      }
      if (!aiFlow.aiFlowVersions) {
        aiFlow.aiFlowVersions = [];
      }
      aiFlow.aiFlowVersions.push(newAiFlowVersion);
      state.selectedAiFlowVersion = newAiFlowVersion;
      let newVersionItems = action.payload.items;
      if (newVersionItems) {
        newVersionItems = newVersionItems.map((item: any, index: any) => {
          const mappedItem = mapAiFlowItemData(
            item,
            newVersionItems,
            state.actions,
            state.actionGroups
          );

          return mappedItem;
        });
        aiFlow.items.push(...newVersionItems);
        orderFlowItems(
          aiFlow.items,
          state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
          newAiFlowVersion?.id as any
        );
        state.aiFlows[aiFlowIndex] = aiFlow;
        if (state.selectedAiFlow?.id === aiFlowId) {
          state.selectedAiFlow = aiFlow;
        }
      }
    },
    addAiFlowItem(state, action) {
      if (!state.selectedAiFlow) {
        return;
      }
      if (!state.selectedAiFlow.items) {
        state.selectedAiFlow.items = [];
      }

      const aiFlowItemsLogic = state.selectedAiFlow.items as AiFlowItem[];
      const newAiFlowItems = _.cloneDeep(aiFlowItemsLogic);
      const item = action.payload.aiFlowItem;
      const mappedItem = mapAiFlowItemData(item, newAiFlowItems, state.actions, state.actionGroups);
      mappedItem.aiFlowVersionId = state.selectedAiFlowVersion?.id as number;
      if (action.payload.addAfter !== undefined) {
        newAiFlowItems.unshift(mappedItem);
      } else {
        newAiFlowItems.push(mappedItem);

        // if (newAiFlowItems.length === 1) {
        //   newAiFlowItems.push({ type: AiFlowItemType.Finish, sequence: 2 })
        // }
      }
      // set sequence ascending order
      orderFlowItems(
        newAiFlowItems,
        state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
        state.selectedAiFlowVersion?.id as any
      );
      state.selectedAiFlow.items = newAiFlowItems;
      state.aiFlows = state.aiFlows.map((af) => {
        if (af.id === state.selectedAiFlow?.id) {
          af.items = newAiFlowItems ?? [];
        }
        return af;
      });
      state.isDirty = true;
      state.loaded = true;
      state.savingAiFlow = false;
    },

    removeAiFlowItem(state, action) {
      if (!state.selectedAiFlow?.items) {
        return;
      }
      let newAiFlowItems = state.selectedAiFlow.items.filter(
        (item) => item.sequence !== action.payload.sequence
      );
      newAiFlowItems = newAiFlowItems.map((item, index) => {
        item.sequence = index + 1;
        return item;
      });
      state.selectedAiFlow.items = newAiFlowItems;
      state.isDirty = true;
    },

    setAiFlowItems(state, action) {
      if (!state.selectedAiFlow) {
        return;
      }
      state.selectedAiFlow.items = action.payload;
    },

    setSavingAiFlow(state, action) {
      state.savingAiFlow = action.payload;
    },

    updateAiFlow(state, action) {
      const tmpAiFlow = action.payload as AiFlow;
      const index = state.aiFlows.findIndex((wf) => wf.id === tmpAiFlow.id);
      if (index > -1) {
        let aiFlowItems = tmpAiFlow.items ? (tmpAiFlow.items as AiFlowItem[]) : [];
        aiFlowItems = aiFlowItems.map((item, ind) => {
          const mappedItem = mapAiFlowItemData(
            item,
            aiFlowItems,
            state.actions,
            state.actionGroups
          );
          return mappedItem;
        });
        orderFlowItems(
          aiFlowItems,
          state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
          state.selectedAiFlowVersion?.id as any
        );
        tmpAiFlow.items = aiFlowItems;
        state.aiFlows[index] = tmpAiFlow;
        if (state.selectedAiFlow?.id === tmpAiFlow.id) {
          state.selectedAiFlow = tmpAiFlow;
        }
        if (state.selectedAiFlowItem) {
          state.selectedAiFlowItem.id =
            tmpAiFlow.items.find((item) => item.sequence === state.selectedAiFlowItem?.sequence)
              ?.id ?? null;
        }
      }
      state.isDirty = false;
    },
    updateAiFlowWithoutItems(state, action) {
      const tmpAiFlow = action.payload as AiFlow;
      const index = state.aiFlows.findIndex((wf) => wf.id === tmpAiFlow.id);
      if (index > -1) {
        console.log('tmpAiFlow', _.cloneDeep(tmpAiFlow));
        console.log('state.aiFlows[index]', _.cloneDeep(state.aiFlows[index]));
        // Destructure the properties of tmpAiFlow except for `items`
        const { items, ...restOfTmpAiFlow } = tmpAiFlow;
        state.aiFlows[index] = { ...state.aiFlows[index], ...restOfTmpAiFlow };
        console.log('state.aiFlows[index]', _.cloneDeep(state.aiFlows[index]));
        if (state.selectedAiFlow?.id === tmpAiFlow.id) {
          console.log('state.selectedAiFlow', _.cloneDeep(state.selectedAiFlow));
          state.selectedAiFlow = { ...state.selectedAiFlow, ...restOfTmpAiFlow };
        }
      }
    },
    addDeletedAiFlowItems(state, action) {
      if (state.selectedAiFlow && state.selectedAiFlow.deletedItems) {
        const index = state.aiFlows.findIndex((wf) => wf.id === action.payload.id);
        if (index > -1 && state.aiFlows[index].deletedItems) {
          state.aiFlows[index].deletedItems = _.cloneDeep(
            _.uniqBy(
              [...(state.aiFlows[index].deletedItems as any[]), ...action.payload.items],
              'id'
            )
          );
          if (state.selectedAiFlow?.id === action.payload.id) {
            state.selectedAiFlow.deletedItems = state.aiFlows[index].deletedItems;
          }
        }
      }
    },
    updateLastSavedSelectedAiFlow(state, action) {
      console.log('action.payload updateLastSaved', _.cloneDeep(action.payload));
      state.lastSavedSelectedAiFlow = { ...state.lastSavedSelectedAiFlow, ...action.payload };
      //   state.isDirty = false;
    },
    updateSelectedAiFlow(state, action) {
      state.selectedAiFlow = action.payload;
      if (state.selectedAiFlow?.items) {
        state.selectedAiFlow.items = state.selectedAiFlow.items.map((item, index) => {
          const mappedItem = mapAiFlowItemData(
            item,
            state.selectedAiFlow?.items ?? [],
            state.actions,
            state.actionGroups
          );
          return { ...mappedItem, sequence: index + 1 };
        });
      }
      state.selectedAiFlowItem = null;
      state.isDirty = true;
    },
    updateSelectedAiFlowItem(state, action) {
      if (!state.selectedAiFlow?.items) {
        return;
      }
      const existAiFlowItem = state.selectedAiFlow.items.find(
        (item) =>
          item.sequence === action.payload.sequence &&
          (item.aiFlowVersionId ? item.aiFlowVersionId === state.selectedAiFlowVersion?.id : true)
      );
      if (!existAiFlowItem) {
        return;
      }
      const index = state.selectedAiFlow.items.indexOf(existAiFlowItem);
      state.selectedAiFlow.items[index] = action.payload;
      if (state.selectedAiFlowItem?.sequence === action.payload.sequence) {
        state.selectedAiFlowItem = action.payload;
      }

      state.aiFlows = state.aiFlows.map((af) => {
        if (af && af.id === state.selectedAiFlow?.id) {
          af.items = state.selectedAiFlow?.items ?? [];
        }
        return af;
      });
    },
    updateTempSelectedAiFlowItem(state, action) {
      state.tempAiFlowItem = action.payload;
    },
    resetSelectedAiFlow(state, action) {
      state.selectedAiFlow = state.aiFlows.find((wf) => wf.id === action.payload) ?? null;
    },

    deleteAiFlowItem(state, action) {
      if (!state.selectedAiFlow?.items) {
        return;
      }
      const newAiFlowItems = state.selectedAiFlow.items.filter(
        (item) => item.sequence !== action.payload.sequence
      );
      const branchItems = newAiFlowItems.filter(
        (item) =>
          item.header === 'Branch' &&
          item.parentSequence === action.payload.sequence &&
          item.aiFlowVersionId === state.selectedAiFlowVersion?.id
      );
      orderFlowItems(
        newAiFlowItems,
        state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
        state.selectedAiFlowVersion?.id as any
      );
      branchItems.forEach((branchItem) => {
        const branchItemIndex = newAiFlowItems.findIndex((item) => item.name === branchItem.name);
        newAiFlowItems.splice(branchItemIndex, 1);
        orderFlowItems(
          newAiFlowItems,
          state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
          state.selectedAiFlowVersion?.id as any
        );
      });
      console.log('newAiFlowItems', newAiFlowItems);
      state.isDirty = true;
      state.selectedAiFlow.items = newAiFlowItems;
    },
    deleteAiFlowItems(state, action) {
      if (!state.selectedAiFlow?.items) {
        return;
      }
      const newAiFlowItems = state.selectedAiFlow.items.filter(
        (item) =>
          !action.payload.some((ai: any) => ai.sequence === item.sequence) &&
          item.aiFlowVersionId === state.selectedAiFlowVersion?.id
      );
      orderFlowItems(
        newAiFlowItems,
        state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
        state.selectedAiFlowVersion?.id as any
      );
      state.selectedAiFlow.items = newAiFlowItems;
      state.isDirty = true;
    },
    replaceItem(state, action) {
      // has old item and new item
      if (!state.selectedAiFlow?.items) {
        return;
      }
      const mappedNewItem = mapAiFlowItemData(
        action.payload.newItem,
        state.selectedAiFlow.items,
        state.actions,
        state.actionGroups
      );
      const newAiFlowItems = state.selectedAiFlow.items.map((item) =>
        item.sequence === action.payload.oldItem.sequence ? mappedNewItem : item
      );
      state.isDirty = true;
      state.selectedAiFlow.items = newAiFlowItems;
    },
    fetchAiFlowVersionsSuccess(state, action) {
      const af = state.aiFlows.find((aiFlow) => aiFlow.id === action.payload.aiFlowId);
      if (af) {
        af.aiFlowVersions = action.payload.aiFlowVersions;
      }
      // if (action.payload.aiFlowVersions && action.payload.aiFlowVersions.length > 0) {
      //   state.selectedAiFlowVersion =
      //     action.payload.aiFlowVersions[action.payload.aiFlowVersions.length - 1];
      // }
    },
    selectAiFlow(state, action) {
      if (!action.payload) {
        state.selectedAiFlow = null;
        state.selectedAiFlowVersion = null;
        return;
      }
      const selAiFlow = _.cloneDeep(action.payload.aiFlow) as any;
      const version = action.payload.version;
      if (version && selAiFlow?.aiFlowVersions) {
        state.selectedAiFlowVersion = selAiFlow.aiFlowVersions?.find(
          (afv: any) => afv.version.toFixed(1) === parseFloat(version).toFixed(1)
        );
        console.log('state.selectedAiFlowVersion', _.cloneDeep(state.selectedAiFlowVersion));
      } else if (selAiFlow?.aiFlowVersions && selAiFlow?.aiFlowVersions.length > 0) {
        state.selectedAiFlowVersion = findLatestVersion(selAiFlow?.aiFlowVersions);
      }
      if (selAiFlow?.items && state.selectedAiFlowVersion?.id) {
        orderFlowItems(
          selAiFlow?.items,
          state.lastSavedSelectedAiFlow?.items as AiFlowItem[],
          state.selectedAiFlowVersion?.id as any
        );
      }
      state.selectedAiFlow = selAiFlow;
      state.lastSavedSelectedAiFlow = _.cloneDeep(state.selectedAiFlow);
    },
    setTestActionResult(state, action) {
      state.testActionResult = action.payload;
      state.testingAction = false;
    },
    setTestingAction(state, action) {
      state.testingAction = action.payload;
    },
    deleteAiFlowsSuccess(state, action) {
      state.aiFlows = state.aiFlows.filter((aiFlow) => aiFlow.id !== action.payload);
    },
    moveAiFlowToFolder(state, action) {
      const aiFlowId = action.payload.aiFlowId;
      const folderId = action.payload.folderId;
      const moveFromFolderId = action.payload.moveFromFolderId;
      const af = state.aiFlows.find((aiFlow) => aiFlow.id === aiFlowId);
      if (!af) {
        return;
      }
      if (af.folderIds && af.folderIds.length > 0) {
        af.folderIds = af.folderIds.filter((id) => id !== moveFromFolderId);
        af.folderIds.push(folderId);
      }
    },
    copyAiFlowToFolder(state, action) {
      const aiFlowId = action.payload.aiFlowId;
      const folderId = action.payload.folderId;
      const af = state.aiFlows.find((aiFlow) => aiFlow.id === aiFlowId);
      if (!af) {
        return;
      }
      if (af.folderIds && af.folderIds.length > 0) {
        af.folderIds.push(folderId);
      }
    },
    removeAiFlowFromFolder(state, action) {
      const aiFlowId = action.payload.aiFlowId;
      const af = state.aiFlows.find((aiFlow) => aiFlow.id === aiFlowId);
      if (!af) {
        return;
      }
      if (af.folderIds && af.folderIds.length > 0) {
        af.folderIds = af.folderIds.filter((id) => id !== action.payload.folderId);
      }
    },
    saveAiFlowItem(state, action) {
      let items = action.payload.items;
      items = items.map((item: any, index: any) => {
        item = mapAiFlowItemData(item, items, state.actions, state.actionGroups);
        // item.sequence = index + 1;
        // if (item.header !== 'Branch') {
        //   item.parentSequence = index > 0 ? index : undefined;
        // } else {
        //   const lastSavedItem = state.lastSavedSelectedAiFlow?.items?.find((i) => i.id === item.id);
        //   debugger;
        //   if (lastSavedItem?.sequence === item.sequence) {
        //     item.parentSequence = lastSavedItem?.parentSequence;
        //   } else if ((lastSavedItem?.sequence as number) > item.sequence) {
        //     item.parentSequence =
        //       (lastSavedItem?.parentSequence as number) -
        //       ((lastSavedItem?.sequence as number) - item.sequence);
        //   } else if ((lastSavedItem?.sequence as number) < item.sequence) {
        //     item.parentSequence =
        //       (lastSavedItem?.parentSequence as number) +
        //       (item.sequence - (lastSavedItem?.sequence as number));
        //   }
        // }
        return item;
      });
      if (state.selectedAiFlow) {
        state.selectedAiFlow.items = items;
        state.selectedAiFlow.lastUpdated = new Date();
        state.lastSavedSelectedAiFlow = _.cloneDeep(state.selectedAiFlow);
        const aiFlowIndex = state.aiFlows.findIndex((af) => af.id === state.selectedAiFlow?.id);
        if (aiFlowIndex > -1) {
          state.aiFlows[aiFlowIndex].lastUpdated = new Date();
        }
      }
    },
    setDirty(state, action) {
      state.isDirty = action.payload;
    },
    setSelectedAiFlowVersion(state, action) {
      state.selectedAiFlowVersion = action.payload;
    },
    saveAiFlowScheduleInfo(state, action) {
      if (state?.selectedAiFlow?.aiFlowScheduleInfo) {
        state.selectedAiFlow.aiFlowScheduleInfo = action.payload.scheduleInfo;
      }
    },
    addCustomAction(state, action) {
      state.customActions.push(action.payload);
    },
    updateCustomAction(state, action) {
      const index = state.customActions.findIndex((ca) => ca.id === action.payload.id);
      if (index > -1) {
        state.customActions[index] = action.payload;
      }
    },
    fetchCustomActionsSuccess(state, action) {
      state.customActions = action.payload.customActions;
    },
    removeCustomAction(state, action) {
      state.customActions = state.customActions.filter((ca) => ca.id !== action.payload);
    },
    selectCustomAction(state, action) {
      state.selectedCustomAction = action.payload;
    },
    addItemNote(state, action) {
      if (state.selectedAiFlowItem) {
        if (!state.selectedAiFlowItem?.aiFlowItemNotes) {
          state.selectedAiFlowItem.aiFlowItemNotes = [];
        }
        state.selectedAiFlowItem.aiFlowItemNotes.unshift(action.payload);
        state.selectedAiFlow?.items?.forEach((item) => {
          if (item.id === state.selectedAiFlowItem?.id) {
            item.aiFlowItemNotes = state.selectedAiFlowItem?.aiFlowItemNotes;
          }
        });
        state.lastSavedSelectedAiFlow = _.cloneDeep(state.selectedAiFlow);
      }
    },
    updateItemNote(state, action) {
      if (state.selectedAiFlowItem) {
        if (!state.selectedAiFlowItem?.aiFlowItemNotes) {
          state.selectedAiFlowItem.aiFlowItemNotes = [];
        }
        const index = state.selectedAiFlowItem.aiFlowItemNotes.findIndex(
          (note) => note.id === action.payload.id
        );
        if (index > -1) {
          state.selectedAiFlowItem.aiFlowItemNotes[index] = action.payload;
          state.selectedAiFlow?.items?.forEach((item) => {
            if (item.id === state.selectedAiFlowItem?.id) {
              item.aiFlowItemNotes = state.selectedAiFlowItem?.aiFlowItemNotes;
            }
          });
          state.lastSavedSelectedAiFlow = _.cloneDeep(state.selectedAiFlow);
        }
      }
    },
    deleteAiFlowItemNote(state, action) {
      if (state.selectedAiFlowItem) {
        if (!state.selectedAiFlowItem?.aiFlowItemNotes) {
          state.selectedAiFlowItem.aiFlowItemNotes = [];
        }
        state.selectedAiFlowItem.aiFlowItemNotes = state.selectedAiFlowItem.aiFlowItemNotes.filter(
          (note) => note.id !== action.payload
        );
        state.selectedAiFlow?.items?.forEach((item) => {
          if (item.id === state.selectedAiFlowItem?.id) {
            item.aiFlowItemNotes = state.selectedAiFlowItem?.aiFlowItemNotes;
          }
        });
        state.lastSavedSelectedAiFlow = _.cloneDeep(state.selectedAiFlow);
      }
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const {
  selectAiFlowItemBySequence,
  selectAiFlowItemById,
  selectAiFlowItem,
  addAiFlowItemBeforeSequence,
  addAiFlowItemsBeforeSequence,
  addAiFlow,
  selectAiFlow,
  addAiFlowItem,
  setSidebarPopoverOpen,
  deleteAiFlowItems,
  replaceItem,
  setNodeClickedSequenceInfo,
  removeAiFlowItem,
  setActionValidated,
  deleteAiFlowItem,
  updateAiFlow,
  setTestActionResult,
  updateTempSelectedAiFlowItem,
  updateAiFlowWithoutItems,
  setSelectedCustomAction,
  updateSelectedAiFlow,
  resetSelectedAiFlow,
  setSelectedAiFlowVersion,
  setCopiedItems,
  updateSelectedAiFlowItem,
  setFullscreen,
  setLoading,
  setDirty,
  setRunMode,
  selectCustomAction,
} = slice.actions;

// ----------------------------------------------------------------------

export function fetchAiFlows() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowAiFlowsGet();
      dispatch(slice.actions.fetchAiFlowsSuccess({ aiFlows: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCustomAction(customActionId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      //     await wfApi.apiAiFlowCustomActionDelete(customActionId);
      dispatch(slice.actions.removeCustomAction(customActionId));
      enqueueSnackbar('Custom Action was deleted succesfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting Custom Action', { variant: 'error' });
    }
  };
}

export function fetchActionGroups() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startFetching());
    try {
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowActionGroupsGet();
      dispatch(slice.actions.fetchActionGroupsSuccess({ actionGroups: response.data }));
      dispatch(fetchAiFlows());
      dispatch(fetchAiFlowRunsItems());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchCustomActions() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const wfApi = new AiFlowApi();
      const customActionsResponse = await wfApi.apiAiFlowCustomActionGet();
      dispatch(
        slice.actions.fetchCustomActionsSuccess({ customActions: customActionsResponse.data })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function fetchAiFlowVersions(aiFlowId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoadingAiFlowVersions(true));
    try {
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowGetAiflowVersionsGet(aiFlowId);
      dispatch(
        slice.actions.fetchAiFlowVersionsSuccess({ aiFlowVersions: response.data, aiFlowId })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoadingAiFlowVersions(false));
    }
  };
}
export function saveAiFlow(
  aiFlow: AiFlowLogic,
  enqueueSnackbar: any,
  navigate: any = null,
  newDraft: boolean = false
) {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const wfApi = new AiFlowApi();
      let response: any;
      dispatch(slice.actions.setSavingAiFlow(true));

      if (!aiFlow.id) {
        aiFlow.folderIds = getState().folders.selectedFolder ? [getState().folders.selectedFolder?.id] : []
        response = await wfApi.apiAiFlowAddAiflowPost({ aiFlow });
        dispatch(slice.actions.addAiFlow(response.data));
        // dispatch(slice.actions.selectAiFlow(response.data));
        // if (selected){
        //   dispatch(slice.actions.selectAiFlow({ aiFlow: response.data }));
        // }
        setTimeout(() => {
          navigate(PATH_MARKETING.pages.editAiFlow(response.data.id as number));
          dispatch(slice.actions.selectAiFlow({ aiFlow: response.data }));
        }, 0);
      } else {
        const items = aiFlow.items;
        const selectedVersion = getState().aiFlows.selectedAiFlowVersion;
        response = await wfApi.apiAiFlowUpdateAiflowPut({
          aiFlow,
          selectedAiFlowVersionId: selectedVersion.id,
        });
        debugger;
        dispatch(slice.actions.updateAiFlow(response.data));
        dispatch(slice.actions.updateLastSavedSelectedAiFlow(response.data));
        return;
        //    dispatch(slice.actions.addDeletedAiFlowItems(aiFlow));
      }
      dispatch(slice.actions.updateLastSavedSelectedAiFlow(aiFlow));
      if (!newDraft && aiFlow.draft) {
        enqueueSnackbar('Flow was saved succesfully', { variant: 'success' });
      }
      //     enqueueSnackbar('Automations were deleted succesfully', { variant: 'success' });
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error saving Flow', { variant: 'error' });
    } finally {
      dispatch(slice.actions.setSavingAiFlow(false));
    }
  };
}

export function saveAiFlowItem(aiFlowItem: AiFlowItemLogic) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setSavingAiFlow(true));
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowSaveAiflowItemPut({ aiFlowItem });
      dispatch(slice.actions.saveAiFlowItem(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setSavingAiFlow(false));
    }
  };
}

export function saveScheduleInfo(scheduleInfo: AiFlowScheduleInfoLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      let response;
      if (scheduleInfo?.id) {
        response = await wfApi.apiAiFlowScheduleInfoPut({ scheduleInfo });
      } else {
        response = await wfApi.apiAiFlowScheduleInfoPost({
          scheduleInfo,
        });
      }
      dispatch(slice.actions.saveAiFlowScheduleInfo({ scheduleInfo: response.data }));
      enqueueSnackbar('Flow Schedule was saved succesfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error saving schedule', { variant: 'error' });
    }
  };
}

export function saveAiFlowWithoutItems(aiFlow: AiFlowLogic) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      let response: any;
      dispatch(slice.actions.setSavingAiFlow(true));
      await wfApi.apiAiFlowSaveAiflowWithoutItemsPut({ aiFlow });
      dispatch(slice.actions.updateAiFlowWithoutItems(aiFlow));
      dispatch(slice.actions.updateLastSavedSelectedAiFlow(aiFlow));
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setSavingAiFlow(false));
    }
  };
}

export function removeAiFlowFromFolder(aiFlowId: number, folderId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      const aiFlowFolder = {
        aiFlowId,
        folderId,
      };
      await wfApi.apiAiFlowRemoveAiflowFromFolderDelete({
        aiFlowFolder,
      });
      dispatch(slice.actions.removeAiFlowFromFolder(aiFlowFolder));
      enqueueSnackbar('Flow was removed from folder succesfully', { variant: 'success' });
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function moveAiFlowToFolder(
  copyMoveAiFolderRequest: CopyMoveAiFolderRequest,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      await wfApi.apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest);
      dispatch(slice.actions.moveAiFlowToFolder(copyMoveAiFolderRequest));
      enqueueSnackbar('Flow was moved to folder succesfully', { variant: 'success' });
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function copyAiFlowToFolder(
  copyMoveAiFolderRequest: CopyMoveAiFolderRequest,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      await wfApi.apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest);
      dispatch(slice.actions.copyAiFlowToFolder(copyMoveAiFolderRequest));
      enqueueSnackbar('Flow was copied to folder succesfully', { variant: 'success' });
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAiFlowItem(enqueueSnackbar: any) {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const selectedItem = getState().aiFlows.selectedAiFlowItem;
      if (!selectedItem) {
        return;
      }
      const selectedAiFlow = getState().aiFlows.selectedAiFlow;
      if (!selectedAiFlow?.id) {
        enqueueSnackbar('AiFlow is not saved yet', { variant: 'error' });
        return;
      }
      dispatch(setLoading(true));
      const wfApi = new AiFlowApi();
      const selectedItemId = selectedItem.id as number;

      const response = await wfApi.apiAiFlowUpsertAiflowItemPut({ aiFlowItem: selectedItem });
      enqueueSnackbar('Saved succesfully', { variant: 'success' });
      const newItem = { ...selectedItem, ...response.data };
      dispatch(updateTempSelectedAiFlowItem(newItem));
      dispatch(slice.actions.updateLastSavedSelectedAiFlow(selectedAiFlow));
      dispatch(setLoading(false));
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAiFlow(aiFlowId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      await wfApi.apiAiFlowDeleteAiflowDelete({ aiFlowId });
      dispatch(slice.actions.deleteAiFlowsSuccess(aiFlowId));
      enqueueSnackbar('AiFlow was deleted succesfully', { variant: 'success' });
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function testAction(actionRequest: TestActionRequest) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setTestingAction(true));
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowTestActionPost(actionRequest);
      dispatch(slice.actions.setTestActionResult(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setTestingAction(false));
    }
  };
}

export function runAiFlow(
  aiFlow: AiFlowLogic,
  aiFlowVersionId: number,
  aiflowItems: AiFlowLogic[],
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const wfApi = new AiFlowApi();
      const runAiFlowResponse = await wfApi.apiAiFlowRunAiflowPost({
        aiFlowId: aiFlow.id as number,
        aiFlowVersionId,
        items: aiflowItems,
      });
      dispatch(addAiFlowRun(runAiFlowResponse.data));
      dispatch(
        addAiFlowRunItems({
          aiFlowRunId: runAiFlowResponse.data.id as number,
          items: aiFlow.items?.filter((i) => i.aiFlowVersionId === aiFlowVersionId) as AiFlowItem[],
        })
      );
      enqueueSnackbar('New run has been added to the queue.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`${error.response.data.Message}`, { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function addCustomAction(customAction: CustomActionLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      const newCustomActionResponse = await wfApi.apiAiFlowCustomActionPost({ customAction });
      dispatch(slice.actions.addCustomAction(newCustomActionResponse.data));
      enqueueSnackbar('New Custom Action has been added.', { variant: 'success' });
      const response = await wfApi.apiAiFlowActionGroupsGet();
      dispatch(slice.actions.fetchActionGroupsSuccess({ actionGroups: response.data }));
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCustomAction(customAction: CustomActionLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      const newCustomActionResponse = await wfApi.apiAiFlowCustomActionPut({ customAction });
      dispatch(slice.actions.updateCustomAction(newCustomActionResponse.data));
      enqueueSnackbar('Custom Action has been updated.', { variant: 'success' });
      const response = await wfApi.apiAiFlowActionGroupsGet();
      dispatch(slice.actions.fetchActionGroupsSuccess({ actionGroups: response.data }));
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNewVersionAiFlow(
  createRequest: CreateAiFlowVersionRequest,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowVersionApi();
      const response = await wfApi.apiAiFlowVersionPost(createRequest);
      dispatch(slice.actions.createNewVersionSuccess(response.data));
      enqueueSnackbar('New version was created succesfully', { variant: 'success' });
      //     enqueueSnackbar('Automations were deleted succesfully', { variant: 'success' });
    } catch (error) {
      //      enqueueSnackbar('Error deleting automations', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error saving Flow', { variant: 'error' });
    }
  };
}

export function addAiFlowItemNote(aiFlowItemNoteLogic: AiFlowItemNoteLogic) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowCreateAiflowitemNotePost({
        aiFlowItemNote: aiFlowItemNoteLogic,
      });
      dispatch(slice.actions.addItemNote(response.data));
      // dispatch(slice.actions.addAiFlowItem(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAiFlowItemNote(aiFlowItemNoteLogic: AiFlowItemNoteLogic) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      const response = await wfApi.apiAiFlowUpdateAiflowitemNotePut({
        aiFlowItemNote: aiFlowItemNoteLogic,
      });
      dispatch(slice.actions.updateItemNote(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAiFlowItemNote(aiFlowItemNoteId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const wfApi = new AiFlowApi();
      await wfApi.apiAiFlowDeleteAiflowitemNoteIdDelete(aiFlowItemNoteId);
      dispatch(slice.actions.deleteAiFlowItemNote(aiFlowItemNoteId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting note', { variant: 'error' });
    }
  };
}
