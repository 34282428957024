import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import integrationsReducer from './slices/integrations';
import settingsReducer from './slices/settings';
import providerReducer from './slices/providers';
import campaignFormsReducer from './slices/campaignForms';
import campaignReducer from './slices/campaigns';
import emailTemplatesReducer from './slices/emailTemplates';
import uploadReducer from './slices/upload';
import editorReducer from './slices/editor';
import craftReducer from './slices/craft';
import contactsReducer from './slices/contacts';
import languageReducer from './slices/language';
import segmentsReducer from './slices/segments';
import tagsReducer from './slices/tags';
import listsReducer from './slices/lists';
import configurationsReducer from './slices/configurations';
import aiFlowsReducer from './slices/aiflows';
import promptFeedbackToolbarReducer from './slices/promptFeedbackToolbar';
import authReducer from './slices/auth';
import userReducer from './slices/user';
import expressionsReducer from './slices/expressions';
import aiFlowsRunsReducer from './slices/aiFlow-runs';
import templatesReducer from './slices/templates';
import organizationReducer from './slices/organization';
import connectionReducer from './slices/connections';
import subscriptionReducer from './slices/subscription';
import folderReducer from './slices/folders';
import creditStatusReducer from './slices/credits-status';
import dashboardReducer from './slices/dashboard';
import initialSetupReducer from './slices/initial-setup';
import notificationsReducer from './slices/notifications';
import promptsReducer from './slices/prompts';
import filesRepoReducer from './slices/files-repo';
import agentsReducer from './slices/agents';
import assistantsReducer from './slices/assistants';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  integration: integrationsReducer,
  agents: agentsReducer,
  assistants: assistantsReducer,
  campaignForms: campaignFormsReducer,
  campaigns: campaignReducer,
  settings: settingsReducer,
  provider: providerReducer,
  emailTemplates: emailTemplatesReducer,
  templates: templatesReducer,
  upload: uploadReducer,
  notifications: notificationsReducer,
  initialSetup: initialSetupReducer,
  editor: editorReducer,
  creditStatus: creditStatusReducer,
  craft: craftReducer,
  contacts: contactsReducer,
  segments: segmentsReducer,
  tags: tagsReducer,
  dashboard: dashboardReducer,
  lists: listsReducer,
  configurations: configurationsReducer,
  aiFlows: aiFlowsReducer,
  aiFlowsRuns: aiFlowsRunsReducer,
  language: languageReducer,
  user: userReducer,
  folders: folderReducer,
  auth: authReducer,
  connection: connectionReducer,
  subscription: subscriptionReducer,
  expressions: expressionsReducer,
  organization: organizationReducer,
  prompts: promptsReducer,
  promptFeedbackToolbar: promptFeedbackToolbarReducer,
  filesRepo: filesRepoReducer
});

export default rootReducer;
