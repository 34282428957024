// routes
import { PATH_MARKETING } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Label from 'src/components/label';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const NAVIGATION_ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  filesRepo: icon('knowledge_base'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('dashboard'),
  integrations: icon('integrations'),
  realAnalytics: icon('analytics'),
  audience: icon('audience'),
  campaigns: icon('campaigns'),
  abtesting: icon('abtesting'),
  products: icon('products'),
  templates: icon('templates'),
  performance: icon('performance'),
  generated: icon('generated'),
  crafted: icon('generated_2'),
  outputs: icon('outputs'),
  deployed: icon('deployed'),
  saved: icon('saved'),
  configurations: icon('configurations'),
  guides: icon('guides'),
  howTo: icon('how-to'),
  aiAssistant: icon('ai_assistant'),
  aiAgents: icon('ai_agents'),
  faq: icon('faq'),
  personas: icon('personas'),
  prompts: icon('prompts'),
  aiFlows: icon('aiflows'),
  actions: icon('actions'),
  team: icon('team'),
  runs: icon('runs'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_MARKETING.root, icon: NAVIGATION_ICONS.dashboard },
      {
        title: 'Integrations',
        path: PATH_MARKETING.integrations.root,
        icon: NAVIGATION_ICONS.integrations,
        // disabled: true,
        // info: <Label color="info">Soon</Label>,
      },
      {
        title: 'Analytics',
        path: PATH_MARKETING.pages.analytics,
        icon: NAVIGATION_ICONS.realAnalytics,
        disabled: true,
        info: <Label color="primary">Soon</Label>,
      },
      {
        title: 'Team',
        path: PATH_MARKETING.team.root,
        icon: NAVIGATION_ICONS.team,
        // disabled: true,
        // info: <Label color="info">Soon</Label>,
      },
      {
        title: 'AI Agents',
        path: PATH_MARKETING.pages.aiAgents,
        icon: NAVIGATION_ICONS.aiAgents,
        // disabled: true,
        // info: <Label color="info">Soon</Label>,
      },
      {
        title: 'AI Assitants',
        path: PATH_MARKETING.pages.aiAssistants,
        icon: NAVIGATION_ICONS.aiAssistant,
        // disabled: true,
        // info: <Label color="info">Soon</Label>,
      }
    ],
  },

  // CONTENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: '',
  //   items: [
  //     // {
  //     //   title: 'A/B Testing',
  //     //   icon: ICONS.abtesting,
  //     //   path: PATH_DASHBOARD.emailMarketing.abTesting,
  //     // },

  //     // {
  //     //   title: 'Automations',
  //     //   icon: ICONS.file,
  //     //   disabled: true,
  //     //   path: PATH_DASHBOARD.emailMarketing.products,
  //     //   info: (
  //     //     <Label color="info">
  //     //       Soon
  //     //     </Label>
  //     //   ),
  //     // },
  //   ],
  // },
  {
    subheader: 'Automation',
    items: [
      {
        title: 'AI Workflows',
        icon: NAVIGATION_ICONS.aiFlows,
        path: PATH_MARKETING.pages.aiFlows,
      },
      {
        title: 'Runs',
        icon: NAVIGATION_ICONS.runs,
        path: PATH_MARKETING.pages.aiFlowsRuns,
      },
      {
        title: 'Templates',
        icon: NAVIGATION_ICONS.templates,
        path: PATH_MARKETING.pages.templates,
        //    disabled: true,
        //    info: <Label color="info">Soon</Label>,
      },
    ],
  },
  {
    subheader: 'My Collections',
    items: [
      // {
      //   title: 'Deployed Apps',
      //   icon: NAVIGATION_ICONS.deployed,
      //   path: PATH_MARKETING.pages.outputs,
      // },
      {
        title: 'Outputs',
        icon: NAVIGATION_ICONS.outputs,
        path: PATH_MARKETING.pages.outputs,
      },
      {
        title: 'Knowledge Bases',
        icon: NAVIGATION_ICONS.filesRepo,
        path: PATH_MARKETING.pages.filesRepos,
        // disabled: true,
        // info: <Label color="info">Soon</Label>,
      },
      {
        title: 'Generative AI',
        icon: NAVIGATION_ICONS.prompts,
        path: PATH_MARKETING.pages.prompts,
        children: [
          {
            title: 'Prompts',
            path: PATH_MARKETING.pages.prompts,
          },
          {
            title: 'Actions',
            path: PATH_MARKETING.pages.customActions,
            // disabled: true,
            // info: <Label color="info">Soon</Label>,
          },
          {
            title: 'Configurations',
            path: PATH_MARKETING.pages.configurations,
          },
          {
            title: 'Fine-tuned Models',
            path: PATH_MARKETING.pages.fineTunedModels,
          },
        ]
      },
     
    ],
  },
   {
    subheader: 'Resources',
    items: [
      {
        title: 'How-to',
        icon: NAVIGATION_ICONS.howTo,
        path: PATH_MARKETING.pages.howTo,
      },
    ],
  },
  // {
  //   subheader: 'Resources',
  //   items: [
  //     {
  //       title: 'Guides',
  //       icon: NAVIGATION_ICONS.guides,
  //       path: PATH_MARKETING.pages.guides,
  //     },
  //     {
  //       title: 'FAQ',
  //       icon: NAVIGATION_ICONS.faq,
  //       path: PATH_MARKETING.pages.faq,
  //     },
  //   ],
  // },
  // {
  //   subheader: 'Email Service Provider',
  //   items: [
  //     {
  //       title: 'Campaigns',
  //       icon: ICONS.campaigns,
  //       path: PATH_MARKETING.marketing.campaigns,
  //     },
  //     // {
  //     //   title: 'Drafts',
  //     //   icon: ICONS.kanban,
  //     //   path: PATH_DASHBOARD.emailMarketing.campaigns,
  //     // },
  //     // {
  //     //   title: 'A/B Tests',
  //     //   icon: ICONS.mail,
  //     //   path: PATH_DASHBOARD.emailMarketing.campaigns,
  //     // },
  //     {
  //       title: 'Audience',
  //       icon: ICONS.audience,
  //       path: PATH_MARKETING.marketing.audience,
  //     },
  //     // {
  //     //   title: 'Products',
  //     //   icon: ICONS.products,
  //     //   path: PATH_DASHBOARD.emailMarketing.products,
  //     // },
  //     {
  //       title: 'Performance',
  //       icon: ICONS.performance,
  //       path: PATH_MARKETING.marketing.performance,
  //     },
  //     {
  //       title: 'My Templates',
  //       icon: ICONS.templates,
  //       path: PATH_MARKETING.marketing.templates,
  //     },
  //   ],
  // },
  // {
  //   subheader: 'Ecommerce',
  //   items: [
  //     {
  //       icon: ICONS.cart,
  //       title: 'Products',
  //       path: PATH_DASHBOARD.emailMarketing.abtestingManagement,
  //     },
  //     { icon: ICONS.blog, title: 'Blogs', path: PATH_DASHBOARD.emailMarketing.abtestingManagement },
  //     { icon: ICONS.blog, title: 'FAQ', path: PATH_DASHBOARD.emailMarketing.abtestingManagement },
  //     {
  //       icon: ICONS.blog,
  //       title: 'Testimonials',
  //       path: PATH_DASHBOARD.emailMarketing.abtestingManagement,
  //     },
  //     {
  //       icon: ICONS.blog,
  //       title: 'Policies',
  //       path: PATH_DASHBOARD.emailMarketing.abtestingManagement,
  //     },
  //     {
  //       icon: ICONS.blog,
  //       title: 'Localization',
  //       path: PATH_DASHBOARD.emailMarketing.abtestingManagement,
  //     },
  //   ],
  // },
];

export default navConfig;
