import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IAssistantState } from 'src/@types/assistant';
// @types
import { EmailProviderApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IAssistantState = {
  assistants: [],
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchAssistantsSuccess(state, action) {
      state.loading = false;
      state.assistants = action.payload.assistants;
    },
    deleteAssistantSuccess(state, action) {
      state.assistants = state.assistants.filter((assistant: any) => assistant.id !== action.payload.assistantId);
    },
    deleteAssistantsSuccess(state, action) {
      state.assistants = state.assistants.filter(
        (assistant: any) => !action.payload.assistantsId.includes(assistant.id)
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchAssistants() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      //   const response = await emailProviderApi.apiEmailProviderAssistantsGet(provider);
      //   dispatch(slice.actions.fetchAssistantsSuccess({ assistants: response.data, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAssistant(assistantId: string, provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderAssistantIdDelete(assistantId, {
      //   providerName: provider,
      // });
      enqueueSnackbar('Assistant was deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteAssistantSuccess({ assistantId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAssistants(assistantsId: string[], provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderAssistantsDelete({
      //   providerName: provider,
      //   assistantsId,
      // });

      enqueueSnackbar('Assistants were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteAssistantsSuccess({ assistantsId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
