import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IAgentState } from 'src/@types/agent';
// @types
import { AgentApi, EmailProviderApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IAgentState = {
  agents: [],
  selectedAgent: null,
  selectedAgentField: null,
  selectedAgentAchorEl: null,
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    createAgentSuccess(state, action) {
      state.agents.push(action.payload);
      state.loading = false;
    },
    fetchAgentsSuccess(state, action) {
      state.loading = false;
      state.agents = action.payload.agents;
    },
    setSelectedAgent(state, action) {
      state.selectedAgent = action.payload;
    },
    setSelectedAgentAnchorEl(state, action) {
      debugger;
      state.selectedAgentAchorEl = action.payload;
    },
    deleteAgentSuccess(state, action) {
      state.agents = state.agents.filter((agent: any) => agent.id !== action.payload.agentId);
    },
    deleteAgentsSuccess(state, action) {
      state.agents = state.agents.filter(
        (agent: any) => !action.payload.agentsId.includes(agent.id)
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { setSelectedAgent, setSelectedAgentAnchorEl } = slice.actions;

// ----------------------------------------------------------------------

export function fetchAgents() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      dispatch(slice.actions.startFetching());
      const agentApi = new AgentApi();
      const agents = await agentApi.apiAgentAgentsGet();
      dispatch(slice.actions.fetchAgentsSuccess({ agents: agents.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createAgent(title: string, description: string, navigate: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const agentApi = new AgentApi();
      const newAgent = await agentApi.apiAgentCreateAgentPost({
        title,
        description,
      });
      dispatch(slice.actions.createAgentSuccess(newAgent.data));
    //  navigate(`/agents/${newAgent.data.id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

}

export function deleteAgent(agentId: string, provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderAgentIdDelete(agentId, {
      //   providerName: provider,
      // });
      enqueueSnackbar('Agent was deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteAgentSuccess({ agentId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAgents(agentsId: string[], provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderAgentsDelete({
      //   providerName: provider,
      //   agentsId,
      // });

      enqueueSnackbar('Agents were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteAgentsSuccess({ agentsId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
