import { Navigate, useRoutes } from 'react-router-dom';
import IntegrationsPage from 'src/pages/integrations/IntegrationsPage';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import {
  // Auth
  LoginPage,
  RegisterPage,
  // Dashboard: General
  DashboardPage,
  // Dashboard: Blog
  //
  BlankPage,
  //
  Page404,
  LoginCallbackPage,
  OAuthCallbackPage,
  EditGeneratedContentPage,
  ContentPage,
  SavedConfigurationPage,
  AiFlowsPage,
  EditPersonaPage,
  AiFlowsRunsPage,
  OutputsPage,
  TemplatesPage,
  UserPage,
  TeamPage,
  EmailVerifyPage,
  PasswordVerifyPage,
  AnalyticsPage,
  CustomActionsPage,
  PromptsPage,
  NewAccountEmailVerifyPage,
  FilesReposPage,
  PromptRepositoriesPage,
  HowToPage,
  AiAgentsPage,
  AiAssistantsPage,
  AiAgentPage,
} from './elements';

import { PATH_MARKETING } from './paths';
import EditAiFlowPage from 'src/pages/aiFlows/EditAiFlowPage';
import DndTest from 'src/components/test/DndTest';
import NewAiFlowPage from 'src/pages/aiFlows/NewAiFlowPage';
import AuthModernLayout from 'src/sections/auth/modern';
import ModernLoginPage from 'src/pages/auth/modern/modern-login-page';
import ModernRegisterPage from 'src/pages/auth/modern/modern-register-page';
import ModernForgotPasswordPage from 'src/pages/auth/modern/modern-forgot-password-page';
import ModernVerifyPage from 'src/pages/auth/modern/modern-verify-page';
import ModernNewPasswordPage from 'src/pages/auth/modern/modern-new-password-page';

// ----------------------------------------------------------------------

export default function Routes() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <AuthModernLayout>
                <ModernLoginPage />
              </AuthModernLayout>
            </GuestGuard>
          ),
        },
        {
          path: 'login-callback',
          element: <LoginCallbackPage />,
        },
        {
          path: 'oauth-callback',
          element: <OAuthCallbackPage />,
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <AuthModernLayout>
                <ModernRegisterPage />
              </AuthModernLayout>
            </GuestGuard>
          ),
        },
        {
          path: 'register/:invitePayload',
          element: (
            <GuestGuard>
              <AuthModernLayout>
                <ModernRegisterPage />
              </AuthModernLayout>
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'forgot-password', element: <ModernForgotPasswordPage /> },
            { path: 'new-password', element: <ModernNewPasswordPage /> },
            { path: 'verify', element: <ModernVerifyPage /> },
          ],
        },
      ],
    },
    {
      path: 'verify-email/:verifyPayload',
      element: <EmailVerifyPage />,
    },
    {
      path: 'verify-new-account/:verifyPayload',
      element: <NewAccountEmailVerifyPage />,
    },
    {
      path: 'verify-password/:verifyPayload',
      element: <PasswordVerifyPage />,
    },

    // Dashboard
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_MARKETING.root} replace />, index: true },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'team', element: <TeamPage /> },
        {
          path: 'integrations',
          element: <IntegrationsPage />,
        },

        // { path: 'actions', element: <ActionsPage /> },
        { path: 'analytics', element: <AnalyticsPage /> },
        { path: 'ai-agents', element: <AiAgentsPage /> },
        { path: 'ai-agents', element: <AiAgentsPage /> },
        { path: 'ai-agents/:id', element: <AiAgentPage /> },
        { path: 'ai-assistants', element: <AiAssistantsPage /> },
        // { path: 'campaigns', element: <CampaignsPage /> },
        // { path: 'expressions', element: <ExpressionsPage /> },
        // { path: 'campaigns/new', element: <NewCampaignPage /> },
        // { path: 'campaigns/edit/:id', element: <EditCampaignPage /> },
        // { path: 'personas', element: <PersonasPage /> },
        // { path: 'personas/new', element: <NewPersonaPage /> },
        { path: 'personas/edit/:id', element: <EditPersonaPage /> },
        { path: 'test', element: <DndTest /> },
        { path: 'content', element: <ContentPage /> },
        // { path: 'content/generated', element: <GeneratedContentPage /> },
        { path: 'outputs', element: <OutputsPage /> },
        { path: 'custom-actions', element: <CustomActionsPage /> },
        { path: 'how-to', element: <HowToPage /> },
        { path: 'content/generated/edit/:id', element: <EditGeneratedContentPage /> },
        { path: 'ai-workflows', element: <AiFlowsPage /> },
        { path: 'runs', element: <AiFlowsRunsPage /> },
        { path: 'runs/:runNumber', element: <AiFlowsRunsPage /> },
        { path: 'ai-workflows/edit/:id', element: <EditAiFlowPage /> },
        { path: 'ai-workflows/new', element: <NewAiFlowPage /> },
        // { path: 'actions/analyze/email-content', element: <AnalyseEmailPage /> },
        // { path: 'actions/analyze/sms-content', element: <AnalyseEmailPage /> },
        // { path: 'actions/generate/email-content', element: <GenerateEmailPage /> },
        // { path: 'actions/generate/prompts', element: <GeneratePromptsPage /> },
        // { path: 'actions/generate/image', element: <GenerateImagePage /> },
        // { path: 'actions/generate/sms-content', element: <GenerateSmsPage /> },
        // { path: 'actions/generate/tailor', element: <TailorPage /> },
        // { path: 'actions/transform/refine', element: <RefinePage /> },
        // { path: 'actions/transform/translate', element: <TranslatePage /> },
        // { path: 'actions/generate/ideas', element: <GenerateEmailIdeasPage /> },
        // { path: 'actions/analysis/content', element: <ContentAnalysisPage /> },
        // { path: 'performance', element: <PerformancePage /> },
        { path: 'templates', element: <TemplatesPage /> },
        { path: 'prompts', element: <PromptRepositoriesPage /> },
        { path: 'files-repos', element: <FilesReposPage /> },
        // { path: 'templates/new', element: <EditTemplatePage /> },
        // { path: 'templates/edit/:id', element: <EditTemplatePage /> },
        // { path: 'audience', element: <AudiencePage /> },
        // { path: 'audience', element: <AudiencePage /> },
        { path: 'configurations', element: <SavedConfigurationPage /> },

        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
